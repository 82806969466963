html {
  font-family: "Gothic A1", sans-serif;
  background-image: url("./images/balloon.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.site-main-outer {
  padding: 1em;
  margin-top: 3em;
  border: 1px solid #ffffff33;
  border-radius: 0.75em;
  backdrop-filter: blur(7px);
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
}

.site-main-inner {
  padding: 1em;
  border-radius: 0.75em;
  background-color: rgba(255, 255, 255, 0.6);
}

.register-input-container,
.password-input-container,
.login-input-container,
.update-form,
.suggestion-form-container {
  display: grid;
}
/* styles the body of the site */

/* adds style to titles, input and a */
input {
  background: transparent;
  justify-content: center;
  border: none;
  border-radius: 0.85em;
  outline: none;
  margin: 5px;
  color: rgba(0, 0, 0, 0.7);
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

textarea {
  font-family: "Gothic A1", sans-serif;
  background: transparent;
  justify-content: center;
  border: none;
  resize: none;
  width: 165%;
  border-radius: 0.85em;
  outline: none;
  /* margin: 5px; */
}

h2 {
  text-align: center;
  margin-bottom: 1.5em;
  color: rgba(0, 0, 0, 0.6);
}

h4,
h3 {
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, 0.7);
}
/* adds style to titles,input and a */

#title {
  font-family: "Pompiere", cursive;
  font-size: 50px;
}
/* styles footer */
.footer-colors {
  text-decoration: none;
  font-size: 16px;
  padding: 1em;
}

#footer {
  text-align: center;
  margin-top: 10%;
}

.footer-colors {
  color: rgb(0, 0, 0, 0.5);
}

.footer-colors:hover {
  color: rgba(0, 0, 0, 0.4);
}

.footer-colors:active {
  color: rgba(0, 0, 0, 0.6);
}

/* styles footer */

.register-form .input-prefix-icon,
.login-form .input-prefix-icon,
.update-form .input-prefix-icon,
.suggestion-form .input-prefix-icon,
.search-container .input-prefix-icon {
  width: 25px;
  color: rgba(0, 0, 0, 0.4);
}

.register-form,
.login-form,
.update-form,
.suggestion-form,
.search-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
}

.register-btn,
.update-btn,
/* .go-back-btn, */
.add-sug-btn,
.paginate-left,
.paginate-right {
  background: transparent;
  /* backdrop-filter: blur(5px);
  align-items: center;
  border: 1px solid #ffffff33;
  border-radius: 0.75em; */
  outline: none;
  color: rgba(0, 0, 0, 0.5);
}

.login-btn,
.go-back-btn {
  background: transparent;
  outline: none;
  margin-top: 0em;
}

#issues {
  color: red;
}
#correct {
  color: green;
}
.register-btn:hover,
.login-btn:hover,
.go-back-btn:hover,
.update-btn:hover,
.add-sug-btn:hover,
.paginate-left:hover,
.paginate-right:hover,
.search-city-btn:hover {
  color: rgba(0, 0, 0, 0.4);
}

.register-btn:active,
.logiin-btn:active,
.go-back-btn:active,
.update-btn:active,
.add-sug-btn:active,
.paginate-left:active,
.paginate-right:active,
.search-city-btn:active {
  color: rgba(0, 0, 0, 0.65);
}

.login-page-btn {
  color: rgba(0, 0, 0, 0.3);
}

.login-btns {
  display: inline-flex;
  align-items: center;
}

#navlink {
  margin-left: 1em;
  align-items: center;
  display: inline-flex;
}

#get-login-btn {
  margin-left: 1em;
}

.register-input-container .register-btn {
  margin: -0.3em;
}

.nav-bar-container {
  display: inline-flex;
  margin: 1em;
  padding: 0.5em;
  margin-top: 1em;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.75em;
  backdrop-filter: blur(7px);
  -webkit-box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 10px 14px -3px rgba(0, 0, 0, 0.45);
}

#home-btn,
#love-btn,
#sign-out-btn,
#config-btn,
#nav-search-btn,
#add-sug-btn,
#sugs-btn,
#reg-nav,
#log-nav {
  padding: 1.5em;
}

.search-btn {
  display: flex;
  align-items: center;
  color: rgb(94, 115, 151);
}

.settings {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  padding: 1em;
  flex-wrap: wrap;
}

.settings #user-edit,
.settings #user-delete,
.settings #user-password {
  padding: 0 3em;
  text-align: center;
}

.button-down,
.button-up {
  background: transparent;
  border: none;
  outline: none;
  color: rgb(94, 115, 151);
}

.button-up.active {
  color: blue;
}

.button-down.active {
  color: red;
}

.suggestion-form.ic-big {
  align-items: flex-start;
  padding-top: 0.5em;
}

.votes-container {
  display: grid;
}

.votes-suggestion-container {
  display: flex;
}
.private-features {
  display: flex;
  padding-bottom: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.inner-modal {
  text-align: center;
  color: #d62828;
}
.modal {
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 5rem auto;
  border-radius: 0.75em;
  max-width: 500px;
  padding: 2rem;
  backdrop-filter: blur(5px);
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal p {
  text-align: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  /* color: #000; */
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  padding: 0.3rem 1rem;
  outline: none;
  margin-left: 0.5rem;
  cursor: pointer;
}

#x-btn {
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.button-default,
.user-delete {
  color: rgba(0, 0, 0, 0.5);
}

.delete-account-btns {
  display: flex;
  justify-content: center;
  padding: 0.2em;
}
#yes-btn,
#no-btn {
  padding: 0.4em;
}
/* Modal styling */

.suggestion-intro-container {
  color: rgba(0, 0, 0, 0.6);
}
.search-container {
  padding: 1em;
}
.search-container input {
  text-align: center;
}
.search-container .input-prefix-icon {
  align-items: center;
  margin: 0em;
}
.paginate-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.votes-info-container {
  display: inline-flex;
}

.select-container {
  background-color: transparent;
  border-radius: 1.5em;
  outline: none;
  width: 100%;
  /* size: 200px; */
  margin-bottom: 15px;
}

#home-btn {
  display: inline-flex;
}

.suggestion-container {
  display: inline-flex;
}

.favorites-container {
  display: grid;
  justify-content: center;
  height: 400px;
  width: 100%;
  overflow: scroll;
}

.pac-target-input {
  width: 100%;
}

.search-city-btn {
  background: transparent;
  outline: none;
}
